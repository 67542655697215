import React from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Button, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Box } from '@mui/material';
import { TActionName } from '@components/common/Card/type';
import { buttonClickLog, productClickEventLog } from '@lib/mixpanel/events';
import { useUserInfo } from '@hooks';
import ScrapProductSkeleton from '@components/_new_components/Pages/main/scrapProducts/ScrapProductSkeleton';
import Title from '@components/_new_components/Pages/main/Title';
import ScrapProduct from '@components/_new_components/Pages/main/scrapProducts/ScrapProduct';
import { CustomIcon } from '@components/ui/Icon';
import {
  Clips_Sort_Criterion,
  Clips_Sort_Key,
  LanguageCodeEnum,
  useGetClipsV2LazyQuery,
  useGetClipsV2Query,
  useProductCardsWithBrandAndCategoriesQuery,
} from '../../../../../src/generated/graphql';
import { UserInfoStatusEnum } from '../../../../../src/provider';

const ScrapProducts = () => {
  const { t, i18n } = useTranslation(['menu']);
  const { isAuthenticated, status } = useUserInfo();
  const theme = useDesignSystemTheme();

  const maxScrapProducts = 20;
  const [nonPromotedScrapQuery, { data: nonPromotedScrapData, loading: nonPromotedScrapLoading }] = useGetClipsV2LazyQuery();
  const { data: promotedScrapData, loading: promotedScrapLoading } = useGetClipsV2Query({
    skip: !isAuthenticated,
    fetchPolicy: 'no-cache',
    variables: {
      productsCondition: {
        isPromote: true,
        isOnSale: true,
      },
      language: i18n.language as LanguageCodeEnum,
      pagination: {
        page: 1,
        limit: 20,
      },
      sort: {
        key: Clips_Sort_Key.CreatedAt,
        criterion: Clips_Sort_Criterion.Desc,
      },
    },
    onCompleted: (data) => {
      const promotedScrap = data.clipsV2;
      if (promotedScrap.length === maxScrapProducts) return;

      nonPromotedScrapQuery({
        fetchPolicy: 'no-cache',
        variables: {
          productsCondition: {
            isPromote: false,
            isOnSale: true,
          },
          language: i18n.language as LanguageCodeEnum,
          pagination: {
            page: 1,
            limit: maxScrapProducts - promotedScrap.length,
          },
          sort: {
            key: Clips_Sort_Key.CreatedAt,
            criterion: Clips_Sort_Criterion.Desc,
          },
        },
      });
    },
  });

  const loadingScrap = promotedScrapLoading || nonPromotedScrapLoading || status === UserInfoStatusEnum.loading;
  const promotedScrap = promotedScrapData?.clipsV2 || [];
  const nonPromotedScrap = nonPromotedScrapData?.clipsV2 || [];
  const mergedScrap = promotedScrap.concat(nonPromotedScrap);

  const { data: scrapProductsData, loading: clipsProductLoading } = useProductCardsWithBrandAndCategoriesQuery({
    skip: loadingScrap,
    variables: {
      languageCode: i18n.language as LanguageCodeEnum,
      productNos: mergedScrap.map((scrap) => scrap.godoGoodsNo),
    },
  });
  const scrapProducts = scrapProductsData?.productCards || [];
  const loadingProducts = loadingScrap || clipsProductLoading;

  const handleProductCardClick = (idx: number) => {
    return (actionName: TActionName, productTitle: string, productId: number, discountRate: number) => {
      productClickEventLog({
        pageName: 'main',
        actionName: actionName,
        productName: productTitle,
        productNo: productId.toString(),
        groupSno: null,
        groupTitle: '스크랩한 상품을 확인해 보세요',
        order: idx + 1,
        discountRate,
      });
    };
  };

  const handleViewAllButtonClick = () => {
    buttonClickLog({ actionName: 'scrap accent - view all product' });
  };

  const handleNoItemButtonClick = () => {
    buttonClickLog({ actionName: 'scrap accent - view sale product' });
  };

  const handleLoginButtonClick = () => {
    buttonClickLog({ actionName: 'scrap accent - sign in' });
  };

  return (
    <Box
      sx={{
        width: 'calc(25% - 96px)',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',

        [theme.breakpoints.down(2184)]: {
          width: 'calc(33% - 96px)',
        },
        [theme.breakpoints.down(1720)]: {
          width: 'calc(33% - 16px)',
        },
        [theme.breakpoints.down('desktop')]: {
          width: '100%',
        },
        [theme.breakpoints.down('smallTablet')]: {
          gap: '8px',
        },
        [theme.breakpoints.up('desktop')]: {
          order: 0,
        },
      }}
    >
      <Title link="/mypage/clip-book" clickMixpanelActionName={'main scrap accent'}>
        {t('main:scrapProducts.title')}
      </Title>

      <Box
        sx={{
          height: '364px',
          borderRadius: '16px',
          boxShadow: `0 0 0 1.5px ${theme.palette['color/gray/100']} inset`,
          padding: '16px',
          gap: '12px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          [theme.breakpoints.down(1720)]: {
            height: '336px',
          },
          [theme.breakpoints.down('desktop')]: {
            height: '324px',
            gap: '8px',
          },
          [theme.breakpoints.down('largeTablet')]: {
            height: '244px',
            borderRadius: '12px',
            padding: '12px',
          },
          [theme.breakpoints.down('smallTablet')]: {
            height: '224px',
          },
        }}
      >
        {!loadingProducts && (
          <React.Fragment>
            {!isAuthenticated && (
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '12px', height: '100%' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="4px"
                  sx={{
                    [theme.breakpoints.down('desktop')]: {
                      '& > div': {
                        width: 106 * 0.938 + 'px !important;',
                        height: 94 * 0.938 + 'px !important;',
                      },
                    },
                    [theme.breakpoints.down('smallTablet')]: {
                      '& > div': {
                        width: 106 * 0.857 + 'px !important;',
                        height: 94 * 0.857 + 'px !important;',
                      },
                    },
                  }}
                >
                  <CustomIcon src={'/icons/Scrap_bg_emoticon.svg'} alt="title" width="106px" height="94px" priority={true} />
                  <Typography variant={'typography/body/medium/regular'} color="color/gray/600" sx={{ textAlign: 'center' }}>
                    {t('main:scrapProducts.login.title')}
                  </Typography>
                </Box>
                <Link href={'/users/login'}>
                  <Button size={'M'} variant={'outlined'} color={'default'} onClick={handleLoginButtonClick}>
                    {t('main:scrapProducts.login.button')}
                  </Button>
                </Link>
              </Box>
            )}

            {isAuthenticated && (
              <React.Fragment>
                {scrapProducts.length > 0 && (
                  <React.Fragment>
                    {scrapProducts.map((product, idx) => (
                      <ScrapProduct key={idx} product={product} onProductClick={handleProductCardClick(idx)} />
                    ))}

                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '14px 0' }}>
                      <Link href={'/mypage/clip-book'} onClick={handleViewAllButtonClick}>
                        <Button size={'M'} color={'default'} variant={'outlined'}>
                          {t('main:scrapProducts.viewAll')}
                        </Button>
                      </Link>
                    </Box>
                  </React.Fragment>
                )}

                {scrapProducts.length === 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '12px', height: '100%' }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                      sx={{
                        [theme.breakpoints.down('desktop')]: {
                          '& > div': {
                            width: 202 * 0.938 + 'px !important;',
                            height: 98 * 0.938 + 'px !important;',
                          },
                        },
                        [theme.breakpoints.down('smallTablet')]: {
                          '& > div': {
                            width: 202 * 0.857 + 'px !important;',
                            height: 98 * 0.857 + 'px !important;',
                          },
                        },
                      }}
                    >
                      <CustomIcon src={'/icons/Scrap_bg_emoticon_login.svg'} alt="title" width="202px" height="98px" priority={true} />

                      <Typography variant={'typography/body/medium/regular'} color="color/gray/600" sx={{ textAlign: 'center', whiteSpace: 'pre' }}>
                        {t('main:scrapProducts.noItem.title')}
                      </Typography>
                    </Box>

                    <Link href={'/event/sale'}>
                      <Button size={'M'} variant={'outlined'} color={'default'} onClick={handleNoItemButtonClick}>
                        {t('main:scrapProducts.noItem.button')}
                      </Button>
                    </Link>
                  </Box>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {loadingProducts && new Array(6).fill(0).map((_, index) => <ScrapProductSkeleton key={index} />)}
      </Box>
    </Box>
  );
};

export default ScrapProducts;
